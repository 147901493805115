<template>
  <v-container>
    <v-progress-circular :size="100" indeterminate />
  </v-container>
</template>

<script>
export default {
  mounted() {
    this.$store.commit('setUser', { save: true });
    this.$store.commit('setForm', undefined);
    this.$router.push({ path: '/auth/login' });
  }
};
</script>
